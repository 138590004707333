<app-header-style-three></app-header-style-three>

<div class='page-title-area' style="background-image: url('{{imageString}}');">
  <div class='container'>
    <div class='page-title-content'>
      <ul>
        <li><a routerLink="/">الرئيسية</a></li>
        <li> <a routerLink="/galleries">{{title}}</a></li>
        <li>{{gallery.description}}</li>
      </ul>
      <h2>{{gallery.description}}</h2>
    </div>
  </div>
</div>

<div class='gallery-area pt-100 pb-70'>
  <div class='container'>
    <div class='row'>
      <ng-container >
        <div *ngFor='let image of _album; let i=index' class='col-lg-4 col-md-6 col-sm-6'>
          <div class='single-gallery-item mb-30'>
            <img (click)='open(i)' [src]='image.thumb' />
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>