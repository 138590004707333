import { Component, OnInit } from '@angular/core';
import { WebsettingService } from 'src/app/_services/websetting.service';

@Component({
  selector: 'app-home-page-two',
  templateUrl: './home-page-two.component.html',
  styleUrls: ['./home-page-two.component.scss']
})
export class HomePageTwoComponent implements OnInit {

  constructor(public webSettingService:WebsettingService) { }

  ngOnInit(): void {
  }

}
