<div class="row">
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class="fa fa-stethoscope" aria-hidden="true"></i>
                كلية الطب
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class="bx bx-laptop"></i>
                الهندسة و تكنولوجيا المعلومات
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                الدراسات العليا
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class="bx bx-briefcase"></i>
                العلوم الإدارية و المالية
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class="bx bx-first-aid"></i>
                العلوم الطبية
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class="fa fa-balance-scale" aria-hidden="true"></i>
                كلية القانون
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class="bx bx-bar-chart-alt-2"></i>
                العلوم الإنسانية
            </a>
        </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class="bx bx-briefcase-alt-2"></i>
                الدراسات المتوسطة
            </a>
        </div>
    </div>
</div>
