<app-header-style-one />

<app-hometwo-main-banner
    *ngIf="webSettingService.webSetting()?.activateSlider"
></app-hometwo-main-banner>

<app-hometwo-about
    *ngIf="webSettingService.webSetting()?.activateAboutUs"
></app-hometwo-about>

<div
    class="courses-categories-area bg-image pt-100 pb-70"
    *ngIf="webSettingService?.webSetting()?.activateElectronicServices"
>
    <div class="container">
        <div class="row">
            <span
                class="d-inline-block px-3 py-2 bg-opacity-5 text-primary rounded mb-2"
                >الخدمات الالكترونية</span
            >
            <div class="col-lg-6 col-sm-6 col-md-4">
                <div class="section-title text-start">
                    <h2>
                        تعرف على خدماتنا الالكترونية <br />
                        لدينا العديد من الخدمات
                    </h2>
                </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-4">
                <div class="section-title text-start">
                    <span class="sub-title"
                        >نقدم مجموعة متنوعة من الخدمات التعليمية التي تهدف إلى
                        تعزيز تجربة التعلم وتنمية مهارات الطلاب. تشمل خدماتنا
                        التعليم الإلكتروني، الإرشاد الأكاديمي، والامتحانات
                        الإلكترونية، بالإضافة إلى توفير بوابات مخصصة للطلاب،
                        المحاضرين، والموظفين لتمكينهم من الوصول إلى جميع الموارد
                        الأكاديمية والإدارية بسهولة</span
                    >
                    <a routerLink="/electronicServices"
                        ><strong>رؤية جميع الخدمات</strong></a
                    >
                </div>
            </div>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div>

<app-hometwo-courses
    *ngIf="webSettingService?.webSetting()?.activateNews"
></app-hometwo-courses>

<div class="courses-categories-area bg-F7F9FB pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span
                class="d-inline-block px-3 py-2 bg-opacity-5 text-primary rounded mb-2"
                >كليات الجامعة</span
            >
            <h2>تصفح كليات الجامعة</h2>
            <span class="sub-title"
                >تضم الجامعة مجموعة متنوعة من الكليات التي تغطي مختلف التخصصات
                الأكاديمية والمهنية.</span
            >
            <!-- <a routerLink="/courses-category-style-3" class="default-btn"><i
                    class='bx bx-show-alt icon-arrow before'></i><span class="label">عرض جميع الكليات</span><i
                    class="bx bx-show-alt icon-arrow after"></i></a> -->
        </div>
        <app-categories-style-three></app-categories-style-three>
    </div>
</div>

<app-our-mission
    *ngIf="webSettingService?.webSetting()?.activateAds"
></app-our-mission>

<app-activity-section
    *ngIf="webSettingService?.webSetting()?.activateActivites"
></app-activity-section>

<div
    class="instructor-area pt-100 pb-70"
    *ngIf="webSettingService?.webSetting()?.activateGallery"
>
    <div class="container">
        <span
            class="d-inline-block px-3 py-2 bg-opacity-5 text-primary rounded mb-2"
            >ألبوم الجامعة</span
        >
        <div class="section-title text-start">
            <h2>تصفح البومات الجامعة</h2>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div>

<app-webinar-countdown
    *ngIf="webSettingService?.webSetting()?.activateTimer"
></app-webinar-countdown>

<div
    class="partner-area bg-color ptb-100"
    *ngIf="webSettingService?.webSetting()?.activatePartners"
>
    <div class="container">
        <div class="section-title">
            <h2>شركاؤنا</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>
