<div class="countdown-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="countdown-content">
                    <h2>{{websettingService?.webSetting()?.timerTitle}}</h2>
                    <a *ngIf="websettingService?.webSetting()?.timerLink && websettingService?.webSetting()?.timerLinkName"
                        [href]="websettingService?.webSetting()?.timerLink"
                        class="sign-up-btn">{{websettingService?.webSetting()?.timerLinkName}} <i
                            class='bx bx-log-in'></i></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="countdown-timer text-center">
                    <div id="timer">
                        <div id="days">{{days}} <span>الأيام</span></div>
                        <div id="hours">{{hours}} <span>ساعة</span></div>
                        <div id="minutes">{{minutes}} <span>دقيقة</span></div>
                        <div id="seconds">{{seconds}} <span>ثانية</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>