<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">الرئيسية</a></li>
                <li>الخدمات الإلكترونية</li>
            </ul>
            <h2>الخدمات الإلكترونية</h2>
        </div>
    </div>
</div>

<div class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bi bi-journal-arrow-up'></i>
                    </div>
                    <h3>النظام الموحد</h3>
                    <a href="https://nsystem.israa.edu.ps/account/login" class="link-btn" target="_blank"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bi bi-person-fill'></i>
                    </div>
                    <h3>بوابة الطالب</h3>
                    <a href="https://student.israa.edu.ps" class="link-btn" target="_blank"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bi bi-person-fill'></i>
                    </div>
                    <h3>بوابة المحاضر</h3>
                    <a href="https://instructor.israa.edu.ps" class="link-btn" target="_blank"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bi bi-person-fill'></i>
                    </div>
                    <h3>بوابة الموظف</h3>
                    <a href="https://hr.israa.edu.ps" class="link-btn" target="_blank"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bi bi-file-person-fill'></i>
                    </div>
                    <h3>بوابة التوظيف</h3>
                    <a href="https://jobs.israa.edu.ps/Login/" class="link-btn" target="_blank"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bi bi-clipboard-check'></i>
                    </div>
                    <h3>الامتحانات الإلكترونية</h3>
                    <a href="https://elearning.israa.edu.ps/Login.aspx" class="link-btn" target="_blank"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bi bi-list-check'></i>
                    </div>
                    <h3>المشد الإلكتروني</h3>
                    <a href="https://israa.edu.ps/e-guid/" class="link-btn" target="_blank"></a>
                </div>
            </div>

        </div>
    </div>
</div>