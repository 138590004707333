<div *ngIf="!loading; else loadingTemplate">
    <app-header-style-one></app-header-style-one>

    <div class="page-title-area" [style.background-image]="backgroundImage">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">الرئيسية</a></li>
                    <li>{{page?.response.title}}</li>
                </ul>
                <h2>{{page?.response.title}}</h2>
            </div>
        </div>
    </div>

    <div class="events-area pt-100 pb-70">
        <div class="container">
            <div class="single-events-box mb-30">
                <div [innerHTML]="page?.response.body"></div>
            </div>
        </div>
    </div>

</div>
<ng-template #loadingTemplate>
    <div>Loading...</div>
</ng-template>