<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template *ngFor="let slider of sliders?.response" carouselSlide>
            <div
                class="main-banner"
                [ngStyle]="{
                    'background-image':
                        'linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0.9)), url(\'' +
                        baseUrlHub +
                        slider?.img +
                        '\')'
                }"
            >
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <i class="bx bxs-graduation"></i>
                                <span class="sub-title"
                                    >طريقك نحو المستقبل</span
                                >
                                <h1 *ngIf="slider.title">
                                    {{ slider?.title }}
                                </h1>
                                <p>{{ slider.description }}</p>
                                <!-- <div class="btn-box">
                                    <a
                                        routerLink="/courses-2-columns-style-2"
                                        class="default-btn"
                                        ><i
                                            class="bx bx-move-horizontal icon-arrow before"
                                        ></i
                                        ><span class="label">View Courses</span
                                        ><i
                                            class="bx bx-move-horizontal icon-arrow after"
                                        ></i
                                    ></a>
                                    <a
                                        routerLink="/contact"
                                        class="optional-btn"
                                        >Get Started Free</a
                                    >
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
