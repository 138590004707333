<div class="row">
    <div class="col-lg-2 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a
                routerLink="/"
                class="d-flex flex-column align-items-center text-center"
            >
                <img
                    class="mb-2"
                    src="../../../../assets/icons/النظام الموحد.svg"
                /><span>النظام الموحد</span>
            </a>
        </div>
    </div>
    <div class="col-lg-2 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a
                routerLink="/"
                class="d-flex flex-column align-items-center text-center"
            >
                <img
                    class="mb-2"
                    src="../../../../assets/icons/خدمات الطالب.svg"
                /><span>خدمات الطالب</span>
            </a>
        </div>
    </div>
    <div class="col-lg-2 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a
                routerLink="/"
                class="d-flex flex-column align-items-center text-center"
            >
                <img
                    class="mb-2"
                    src="../../../../assets/icons/بوابة المحاضر.svg"
                /><span>بوابة المحاضر</span>
            </a>
        </div>
    </div>
    <div class="col-lg-2 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a
                routerLink="/"
                class="d-flex flex-column align-items-center text-center"
            >
                <img
                    class="mb-2"
                    src="../../../../assets/icons/الامتحانات الالكترونية.svg"
                /><span>الامتحانات الإلكترونية</span>
            </a>
        </div>
    </div>
    <div class="col-lg-2 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a
                routerLink="/"
                class="d-flex flex-column align-items-center text-center"
            >
                <img
                    class="mb-2"
                    src="../../../../assets/icons/التعليم الالكتروني.svg"
                /><span>التعليم الإلكتروني</span>
            </a>
        </div>
    </div>
    <div class="col-lg-2 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a
                routerLink="/"
                class="d-flex flex-column align-items-center text-center"
            >
                <img
                    class="mb-2"
                    src="../../../../assets/icons/بوابة التوظيف.svg"
                /><span>بوابة التوظيف</span>
            </a>
        </div>
    </div>
</div>
