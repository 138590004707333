import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
    selector: "app-categories-style-one",
    templateUrl: "./categories-style-one.component.html",
    styleUrls: ["./categories-style-one.component.scss"],
})
export class CategoriesStyleOneComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
