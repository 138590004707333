<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{contactUs}}</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">{{ourLocation}}</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">{{phoneNumber}}</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello@ednuv.com">{{email}}</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">Web Design</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Web Development</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="single-footer-widget mb-30 d-flex justify-content-center">
                    <!-- Israa Location From google map -->
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.0492975243196!2d34.45478972536445!3d31.522805947023457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14fd7fb07352b9d3%3A0x3d9f850ea57597e!2z2KzYp9mF2LnYqSDYp9mE2KfYs9ix2KfYoS3Yutiy2KkgSXNyYWEgVW5pdmVyc2l0eQ!5e0!3m2!1sar!2s!4v1723370813318!5m2!1sar!2s"
                        style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/israaImg/FooterAndDashboard.png"
                        alt="image"></a>
            </div>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>