import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  contactUs = 'اتصل بنا'
  ourLocation = 'فلسطين - غزة'
  phoneNumber = '+972 596215487'
  email = 'israa@eud.ps'
  constructor() { }

  ngOnInit(): void {
  }

}
