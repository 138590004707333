import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Lightbox } from "ngx-lightbox";
import { GalleryService } from "src/app/_services/gallery.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-gallery-page",
    templateUrl: "./gallery-page.component.html",
    styleUrls: ["./gallery-page.component.scss"],
})
export class GalleryPageComponent implements OnInit {
    gallery: any = {};
    public _album = [];
    baseUrl = environment.API_URL_HUB;
    imageString = "";
    title: string = "";

    ngOnInit(): void {
        const galleryId = this.route.snapshot.paramMap.get("id");
        this.getAll(+galleryId);
    }

    constructor(
        public _lightbox: Lightbox,
        private route: ActivatedRoute,
        private _galleryService: GalleryService,
        private _router: Router
    ) {
        const navigation = this._router.getCurrentNavigation();
        console.log(navigation?.extras?.state);
        if (navigation?.extras?.state.imageString != null) {
            console.log(navigation?.extras?.state);
            
            localStorage.setItem(
                "galleryImage",
                this.baseUrl + navigation?.extras?.state.imageString
            );
        }
      

        this.imageString = localStorage.getItem("galleryImage");

        this.title = "ألبومات الصور";
    }

    getAll(id: number) {
        this._galleryService.get(id).subscribe({
            next: (res) => {
                this.gallery = res.response;
            },
            complete: () => {
                for (let image of this.gallery.images) {
                    const src = this.baseUrl + image.path;
                    const caption = "Image ";
                    const thumb = this.baseUrl + image.path;
                    const album = {
                        src: src,
                        caption: caption,
                        thumb: thumb,
                    };
                    this._album.push(album);
                }
            },
        });
    }

    open(index: number): void {
        // open lightbox
        this._lightbox.open(this._album, index);
    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }
}
