<section class="courses-area pt-100 pb-70">
    <div class="container">
        <span
            class="d-inline-block px-3 py-2 bg-opacity-5 text-primary rounded mb-2"
            >اخر الأخبار</span
        >
        <div class="section-title text-start">
            <h2>تصفح اخر الأخبار</h2>
            <a routerLink="/news" class="default-btn">
                <i class="bx bx-show-alt icon-arrow before"></i>
                <span class="label">عرض جميع الأخبار</span>
                <i class="bx bx-show-alt icon-arrow after"></i
            ></a>
        </div>
        <div class="tabs-container">
            <div *ngIf="currentTab === 'tab1'" class="pane" id="tab1">
                <div class="row">
                    <div *ngFor="let post of posts" class="col-lg-4 col-md-6">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <img [src]="baseUrlHub + post.img" alt="" />
                                <div class="courses-tag">
                                    <a class="d-block" routerLink="/">{{
                                        post?.category
                                    }}</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <h3>
                                    <a
                                        style="
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                        "
                                        [routerLink]="'/news/' + post.id"
                                        >{{ post.title }}</a
                                    >
                                </h3>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class="bi bi-calendar"></i>
                                        {{
                                            post.publishedAt
                                                | date : "dd/MM/yyyy"
                                        }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
