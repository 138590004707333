import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  baseUrl = environment. API_URL+ "Pages/";
  constructor(private _http:HttpClient) { }

  get(id:number):Observable<any>{
   return this._http.get<any>(`${this.baseUrl}${id}`);
  }  
}